import React from "react";
import styles from "../Dashboard.module.css";
import { useBilling, useBillingDetails } from "../../../store/store";
import { useNavigate } from "react-router-dom";

const BillingDashboard = () => {
  const [sBilling] = useBilling();
  const [billingDetails] = useBillingDetails();
  const navigate = useNavigate();

  return (
    <div
      className={`${styles["configure-tab"]} ${
        sBilling ? styles["outline-success"] : styles["outline-fail"]
      }`}
    >
      <h2 className={styles["box-header"]}>Billing Configuration</h2>
      <div className={styles["configured-info"]}>
        <div className={styles["configure-metadata-1"]}>
          <div className={styles["configure-status"]}>
            <div className={styles["configure-props"]}>
              {!sBilling && "Not"} Configured
            </div>
            <div
              className={`${styles["configure-dot"]} ${
                sBilling ? styles["green-dot"] : styles["red-dot"]
              }`}
            ></div>
          </div>
          <div className={styles["configure-value"]}>Status</div>
        </div>
        <div className={styles["configure-metadata-2"]}>
          <div className={styles["configure-props"]}>
            {sBilling && billingDetails
              ? billingDetails.interval.charAt(0).toUpperCase() +
                billingDetails.interval.slice(1) +
                "ly"
              : "N/A"}
          </div>
          <div className={styles["configure-value"]}>Plan</div>
        </div>
        <div className={styles["configure-metadata-3"]}>
          <button
            onClick={() => navigate("/billing")}
            className={styles["action-btn"]}
          >
            Go to Billing
          </button>
        </div>
      </div>
    </div>
  );
};

export default BillingDashboard;
